import { useEffect } from "react";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
//
import { FaHatWizard, FaStar } from 'react-icons/fa';
import { IoGameControllerOutline } from 'react-icons/io5';
import { AiOutlineArrowDown } from 'react-icons/ai';

import SushilImg from "../../assets/images/sushil.jpg";
import LaptopSvg from "../../assets/svg/Laptop";
import TimelineElement from "../../components/common/TimelineElement/TimelineElement";

//
import { TIMELINE_DATA } from "./_data";

//
import 'react-vertical-timeline-component/style.min.css';
import transition from "../../transition";

/**
 * 
 */
function AboutMePage() {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const viewTimeLine = () => {
        window.scrollTo({
            top: 800,
            left: 0,
            behavior: "smooth"
        })
    }

    return (
        <div className="container px-2 sm:px-0 mx-auto min-h-screen py-20">
            <div className="relative overflow-hidden ">
                <LaptopSvg className="absolute bottom-[50px] z-0 w-[900px] -right-[190px] scale-[1.5] -rotate-12" />
                <IoGameControllerOutline fontSize={50} color="#ef444493" className="absolute bottom-[600px] z-0 w-[900px] -right-[30px] scale-[2] -rotate-12" />
                <FaHatWizard fontSize={80} color="#ef44449b" className="absolute bottom-[250px]  w-[300px] z-0 right-[720px] scale-[1.5] rotate-12" />

                <div className="z-10 h-full w-full rounded-lg bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-10 px-4 xl:px-2">
                    <h1 className="text-5xl font-bold text-red-400 dark:text-red-500 ">
                        Sushil S. Bajracharya
                    </h1>

                    <div className="mt-10 grid grid-cols-1 lg:grid-cols-2 gap-10 lg:gap-5">
                        <img className="h-auto w-full max-w-xl mx-auto lg:mx-0 rounded-lg shadow-xl dark:shadow-gray-800" src={SushilImg} alt="sushil bajracharya" />

                        <div>
                            <h3 className="text-xl sm:text-2xl mb-3">
                                I'm a software engineer with over three years of experience in the field. My primary focus has been on front-end development, with a specialization in ReactJS. I'm passionate about creating functional and attractive user interfaces that provide a seamless and enjoyable experience for users.
                            </h3>

                            <p className="italic">
                                I've enjoyed a diverse career, starting with a React developer internship during my Bachelor's where I built websites for blogs and universities. I also created educational games using React and Unity for children. Working with global clients, I developed solutions while handling research, documentation, and effective communication. I've contributed to impactful government projects within collaborative teams.
                            </p>

                            <button className="hidden lg:flex items-center mt-20 bg-red-400 dark:bg-red-500 text-white px-6 py-3 rounded-md hover:shadow-lg hover:-translate-y-1  transition-transform active:translate-y-0 active:shadow-none "
                                onClick={viewTimeLine}>
                                <AiOutlineArrowDown className="mr-2" />
                                View Timeline</button>
                        </div>

                    </div>
                </div>
            </div>

            <div className="mt-20 mx-4 xl:mx-2 shadow-md rounded-lg bg-red-500 dark:bg-gradient-to-b dark:from-[#2B3747] dark:to-[#1C539B]">
                <div className="pt-10 text-center">
                    <h2 className="text-2xl md:text-4xl font-semibold text-gray-50">
                        Work experiences & Education
                    </h2>

                    <div className="border-2 rounded w-1/3 mx-auto mt-4 mb-10 relative" >
                        <div className="absolute w-5 h-5 rounded-full left-1/2 translate-x-[-50%] translate-y-[-50%] bg-white" />
                    </div>
                </div>

                <VerticalTimeline>
                    {
                        TIMELINE_DATA.map((event, index) => (
                            <TimelineElement
                                key={index}
                                type={event.type}
                                date={event.date}
                                title={event.title}
                                subTitle={event.subTitle}
                                description={event.description}
                                contact={event.contact}
                            />)
                        )
                    }
                    <VerticalTimelineElement
                        iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
                        icon={<FaStar className="animate-spin-slow" />}
                    />
                </VerticalTimeline>
            </div>
        </div>
    )
}

export default transition(AboutMePage);