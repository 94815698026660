export const TIMELINE_DATA = [
    {
        type: "school",
        date: "2016 - 2022",
        title: "Bachelors of Science in Computer Science and Information Technology (B.Sc.CSIT)",
        subTitle: "Madan Bhandari Memorial College",
        description: "I embarked on a four-year journey in Computer Science and Information Technology, but due to COVID-19 pandamic interference the course took more time to complete. Over this period, my learning spanned from foundational topics such as microprocessors, compilers, C, and C++, to more advanced domains like cryptography, networking, software development and AI. During this time, I actively engaged in self-directed learning, with invaluable guidance from my professors, which led me to acquire skills in JavaScript and ultimately set my sights on a career as a web developer. "
    },
    {
        type: "event",
        date: "2018",
        title: "ReactJS Workshop",
        subTitle: "Frontend Community Nepal, Developer Circles Kathmandu",
        description: "It gave me my first glimpse of ReactJs. I was immediately captivated by its capabilities and potential in modern web development. This experience ignited a strong desire within me to delve deeper into React and pursue it as a core skill in my career. The workshop was a pivotal moment in my journey, sparking my passion for frontend development with React."
    },
    {
        type: "work",
        date: "2018",
        title: "React Intern",
        subTitle: "Oya Opportunities",
        description: "I got the opportunity to put my React.js skills into action and gain hands-on experience in transforming a website into a React-based application. This experience not only deepened my technical proficiency but also allowed me to understand the intricacies of real-world project implementation."
    },
    {
        type: "work",
        date: "2020",
        title: "Software Developer",
        subTitle: "OLE Nepal",
        description: "Developed educational stories and games for children using a combination of React, GSAP, and jQuery. I also worked closely with designers, UX developers, and project leads to develop high-quality products. This hands-on experience allowed me to apply my technical skills in a creative and user-centric manner."
    },
    {
        type: "event",
        date: "2020",
        title: "Let's React",
        subTitle: "ReactJs bootcamp conducted by me",
        description: "I conducted a ReactJS bootcamp for my bachelor's classmates, where I shared my knowledge, highlighted React's capabilities, and provided fundamental insights into this technology."
    },
    {
        type: "work",
        date: "2022",
        title: "Frontend Engineer",
        subTitle: "Perceive now",
        description: "Developed responsive websites by converting design requirements, ensuring user-friendly and adaptable interfaces. I conducted research and utilized the D3.js library to generate graphs for data visualization. Worked within the team, acquiring knowledge, sharing insights, and actively contributing to the product development process."
    },
    {
        type: "work",
        date: "2021 - Present",
        title: "Software Engineer",
        subTitle: "Idea Breed IT Solution Pvt. Ltd.",
        description: "Working as a software engineer, I develop reusable components and layouts. My responsibilities include setting up projects, researching and implementing the necessary packages, and efficiently addressing client requirements to deliver effective solutions. These experiences are enriching my skill set and allowing me to make significant contributions to diverse software development projects."
    },
    {
        type: "seek",
        date: "Present",
        title: "Exploring New Horizons: Open to Exciting Opportunities",
        description: "As a dedicated developer, I am on the lookout for fresh challenges and opportunities to expand my skills and contribute to innovative projects. With a passion for crafting efficient and elegant solutions, I am eager to bring my expertise to a dynamic team that values creativity and embraces technological advancements. Let's connect and explore how my skills can align with your organization's goals, driving success in our mutual journey",
        contact: "/p/contact"
    },
]