import { useEffect, useState } from "react"
import Blog from "./Blog/Blog";

/**
 * 
 */
export default function Blogs() {
    const [blogs, setBlogs] = useState([]);

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await fetch("https://dev.to/api/articles?username=sushilbajracharya01");
                const results = await response.json();
                console.log(results, 'results')

                setBlogs(results);
            }
            catch (error) {
                console.log(error, 'error')
            }
        }

        fetchBlogs();
    }, []);

    return <div id="blogs" className="container mx-auto min-h-screen py-20">
        <div className="text-center">
            <h1 className="dark:text-white font-semibold font-poppins text-4xl lg:text-6xl mt-4 md:mt-0 mb-6" data-aos="fade-down">
                My Recent Blogs
            </h1>
            <p data-aos="fade-down" data-aos-delay="200">Discover a small collection of my recent blogs</p>
        </div>
        <div className="my-10 mx-4 grid gap-12 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
            {
                blogs.map((blog, index) => <Blog
                    title={blog.title}
                    description={blog.description}
                    key={blog.id}
                    url={blog.url}
                    tags={blog.tag_list}
                    image={blog.cover_image}
                    readingTime={blog.reading_time_minutes}
                    delay={index}
                />)
            }
        </div>
    </div>
}