import React from 'react'
import { FaBookOpen } from 'react-icons/fa'

function Blog({
    delay,
    title,
    description,
    url,
    tags,
    image,
    readingTime,
}) {
    return (
        <div data-aos="fade-down" data-aos-delay={`${400 + (delay * 200)}`}>
            <div className="max-w-sm mx-auto rounded cursor-default overflow-hidden shadow-lg transition-transform transform hover:scale-105 dark:bg-slate-600  duration-1000 h-full flex flex-col justify-between">
                <div className='relative'>
                    <img
                        className="w-full"
                        src={image}
                        alt={title}
                    />

                    <a href={url} target='_blank' rel='noreferrer' className='absolute text-sm flex items-center gap-2 rounded-md top-0 right-0 px-3 py-1 m-2 font-bold bg-gray-100 transition-opacity opacity-25 hover:opacity-75'>
                        <FaBookOpen />
                        <span>
                            {readingTime} <span className=''>mins</span>
                        </span>
                    </a>

                    <div className="px-6 py-4">
                        <div className="font-bold text-xl mb-2">
                            <a href={url} target='_blank' rel='noreferrer' className='transition-colors hover:text-blue-600'>
                                {title}
                            </a>
                        </div>

                        <p className="text-gray-700 dark:text-slate-200 text-base mb-4">
                            {description}
                        </p>

                        <div>
                            {
                                tags.map(hashtag => <span key={hashtag} className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                                    #{hashtag}
                                </span>)
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Blog