export default function Input({ label, name, type = "text", required, disabled, onChange, value, placeholder, className }) {
    let element = null;

    switch (type) {
        case 'textarea':
            element = <textarea name={name} id={name} className="block w-full rounded-md border-0 py-1.5 pl-4 pr-4 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-400 sm:text-sm sm:leading-6 outline-red-400
            disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
                rows={7}
                required={required}
                disabled={disabled}
                placeholder={placeholder}
            />
            break;
        default:
            element = <input name={name} id={name} className="block w-full rounded-md border-0 py-1.5 pl-4 pr-4 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-400 sm:text-sm sm:leading-6 outline-red-400
            disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none" type={type} required={required}
                disabled={disabled}
                placeholder={placeholder}
            />
    }
    return (
        <div className="w-full">
            <label htmlFor={name} className="w-full block text-sm font-semibold leading-6">
                {label}{!required && <span className="text-xs ml-1 opacity-70">(optional)</span>}
            </label>
            {element}
        </div>
    )
}