import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  user: null,
  isLoggedIn: false,
};

// A slice
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setIsLoggedIn: (state) => {
      state.isLoggedIn = true;
    },
    setLoggout: (state) => {
      state.isLoggedIn = false;
      state.user = null;
    },
  },
});

const { setUser, setIsLoggedIn } = userSlice.actions;

export const userSelector = (state) => state.user;

const userReducer = userSlice.reducer;

export default userReducer;

// Actions
export const setUserHandler = (user) => async (dispatch) => {
  dispatch(setUser(user));
};

export const setLoginUserHandler = (user) => async (dispatch) => {
  dispatch(setUser(user));
  dispatch(setIsLoggedIn());
};
