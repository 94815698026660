import Aos from "aos";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

//
import DynamicHeader from "../../DynamicHeader";
import Footer from "../../Footer";
import ScrollToTopButton from "../ScrollToTopButton";

/**
 * 
 */
export default function DynamicLayout() {
    const darkMode = useSelector((state) => state.ui.darkMode);

    useEffect(() => {
        Aos.init({
            duration: 750,
            offset: 200,
        });
    });
    return (
        <div className={`${darkMode ? "dark" : ""} layout`} id="home">
            <div className=" text-slate-700 dark:bg-slate-700 dark:text-slate-200 transition-colors duration-1000">
                <DynamicHeader />
                <Outlet />
                <Footer />

                <ScrollToTopButton />
            </div>
        </div>
    )
}