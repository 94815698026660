import React from "react";

function NavLink({ title, href, className = "", isActive }) {

  return (
    <a href={href}>
      <span
        className={`block text-xl mt-4 md:inline-block md:mt-0 mr-10 hover:text-red-400 dark:hover:text-slate-50 ${isActive ? "text-red-500" : ""
          }
        ${className}
        `}
      >
        {title}
      </span>
    </a>
  );
}

export default NavLink;
