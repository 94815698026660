import React from 'react'
import { FaLaptop, FaRegEye, FaSchool } from 'react-icons/fa';
import { BsStars } from 'react-icons/bs';
import { VerticalTimelineElement } from 'react-vertical-timeline-component'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

function TimelineElement({ type, className, date, title, subTitle, description, contact }) {
    const darkMode = useSelector(state => state.ui.darkMode);
    let classNames = '', icon = null, iconStyle = null, contentArrowStyle = null, contentStyle = null;
    let titleClasses = '';
    let subTitleClasses = '';

    switch (type) {
        case 'work':
            classNames = `vertical-timeline-element--work ${className}`;
            iconStyle = { background: 'rgb(33, 150, 243)', color: '#fff' }
            icon = <FaLaptop />;
            titleClasses = 'text-blue-500';
            subTitleClasses = 'text-blue-400';
            contentStyle = darkMode ? {
                backgroundColor: '#b3d4ff',
            } : null;

            break;
        case 'school':
            classNames = `vertical-timeline-element--education ${className}`;
            iconStyle = { background: 'rgb(233, 30, 99)', color: '#fff' }
            icon = <FaSchool />
            titleClasses = 'text-red-500';
            subTitleClasses = 'text-red-400';
            contentStyle = darkMode ? {
                backgroundColor: '#ffe7e7',
            } : null;
            break;
        case 'event':
            classNames = `vertical-timeline-element--education ${className}`;
            iconStyle = { background: 'rgb(233, 162, 30)', color: '#fff' }
            icon = <BsStars />
            titleClasses = 'text-orange-500';
            subTitleClasses = 'text-orange-400';
            contentStyle = darkMode ? {
                backgroundColor: '#fff6ef',
            } : null;
            break;
        case 'seek':
            classNames = `vertical-timeline-element--seek ${className}`;
            iconStyle = { background: 'rgb(255, 233, 33)', color: '#343434' }
            icon = <FaRegEye />
            titleClasses = 'text-yellow-500';
            contentStyle = darkMode ? {
                backgroundColor: '#fff6ef',
            } : null;
            break;
        default:

    }


    return (
        <VerticalTimelineElement
            className={classNames}
            contentStyle={contentStyle}
            contentArrowStyle={contentArrowStyle}
            dateClassName='text-white font-semibold'
            date={date}
            iconStyle={iconStyle}
            icon={icon}
        >
            <h3 className={`${titleClasses} text-xl font-semibold`}>{title}</h3>
            <h4 className={`${subTitleClasses} text-md`}>{subTitle}</h4>
            <p className='dark:text-gray-700'>
                {description}
            </p>
            {
                contact && <Link className='inline-block mt-6 bg-slate-100 text-red-600 dark:bg-red-500 dark:text-white px-6 py-3 rounded-md hover:shadow-lg hover:-translate-y-1 transition-transform active:translate-y-0 active:shadow-none disabled:border-2 disabled:bg-transparent disabled:text-white disabled:cursor-not-allowed disabled:shadow-none disabled:translate-y-1 disabled:opacity-60 min-w-[80px]'
                    to={contact}>Contact Me</Link>
            }
        </VerticalTimelineElement>
    )
}

export default TimelineElement