import { Link } from "react-router-dom";

import Highlighter from "../../components/Highlighter";

//
import SectionWrapper from "../../components/common/SectionWrapper/SectionWrapper";

//
import SushilImg from "../../assets/images/sushil.jpg";

/**
 * linear-gradient(45deg, #0f324e, #7d5982)
 */
export default function About() {
  return (
    <div className="pt-32 -mt-32" id="about">
      <SectionWrapper className="bg-gradient-to-r from-red-500 to-pink-500 dark:bg-gradient-to-b dark:from-[#2B3747] dark:to-[#1C539B] mb-10 min-h-100 relative flex justify-center items-center transition-colors duration-1000">
        <div className="custom-shape-divider-top-1653921408">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              className="shape-fill dark:fill-slate-700 transition-colors duration-1000"
            ></path>
          </svg>
        </div>
        <div className="container my-32	 md:my-auto mx-auto flex justify-around flex-wrap ">
          <div
            className=" w-full md:w-2/5 flex text-center justify-center items-center "
            data-aos="fade-right"
            data-aos-delay="500"
            data-aos-duration="1200"
          >
            <div className="bg-slate-800 w-2/4 rounded-full overflow-hidden">
              <img
                className="object-cover"
                src={SushilImg}
                alt="sushil bajracharya profile pic"
              />
            </div>
          </div>
          <div className="w-full md:w-3/5">
            <div className="mx-2">
              <h1 className="text-red-100 dark:text-white text-center md:text-left font-semibold font-poppins text-4xl lg:text-6xl mt-4 md:mt-0 mb-6" data-aos="fade-left">
                About Me
              </h1>

              <p className="text-red-100 dark:text-white text-center w-full md:w-4/5 md:text-left md:text-2xl leading-relaxed antialiased sm:subpixel-antialiased md:antialiased lg:subpixel-antialiased xl:antialiased" data-aos="fade-left"
                data-aos-delay="300">
                My name is <Highlighter>Sushil Bajracharya</Highlighter>, I am a{" "}
                <Highlighter>software engineer</Highlighter> based in Nepal. I
                enjoy creating things for the internet. I am fueled by my{" "}
                <Highlighter>
                  passion for learning and creating solutions{" "}
                </Highlighter>
                for making lives easier.
              </p>
              <div className="flex justify-center md:justify-start">
                <Link to='/p/about-me' className="inline-block mt-6 bg-slate-100 text-red-600 dark:bg-red-500 dark:text-white px-6 py-3 rounded-md hover:shadow-lg hover:-translate-y-1 transition-transform active:translate-y-0 active:shadow-none"
                  data-aos="fade-left"
                  data-aos-delay="300"
                >Know more about me</Link>
              </div>
            </div>
          </div>
        </div>
        <div className="custom-shape-divider-bottom-1653921638 block md-none">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              className="shape-fill dark:fill-slate-700 transition-colors duration-1000"
            ></path>
          </svg>
        </div>
      </SectionWrapper>
    </div>
  );
}
