import { useRef, useState } from "react";
import Input from "../common/Input/Input";
import emailjs from '@emailjs/browser';
import { toast } from "react-toastify";

import { VscLoading } from 'react-icons/vsc';

export default function ContactForm() {
    const formRef = useRef();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, formRef.current, process.env.REACT_APP_PUBLIC_KEY)
            .then(() => {
                formRef.current.reset();
                toast.success(`Thank you for reaching out! I'll be sure to get back to you soon.`)
                setIsSubmitting(false);
            }, (error) => {
                console.log(error.text);
                setIsSubmitting(false);
            });

    };
    return (
        <form ref={formRef} className="max-w-3xl h-full w-full mt-6 mx-auto bg-white-100 rounded-md bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-20 p-4 text-white"
            data-aos="fade-left"
            onSubmit={sendEmail}
        >
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
                <Input label={"FULLNAME"} name="from_name" required disabled={isSubmitting} placeholder="Your fullname" />

                <Input label={"E-MAIL"} type="email" name="email" required disabled={isSubmitting} placeholder="Your email" />
            </div>
            <div className="grid grid-cols-1 gap-4">
                <Input label={"SUBJECT"} name="subject" disabled={isSubmitting} placeholder="Write the subject" />
                <Input label={"MESSAGE"} type="textarea" name="message" required disabled={isSubmitting} placeholder="Write anything" />
            </div>

            <button className="inline-block mt-6 bg-slate-100 text-red-600 dark:bg-red-500 dark:text-white px-6 py-3 rounded-md hover:shadow-lg hover:-translate-y-1 transition-transform active:translate-y-0 active:shadow-none disabled:border-2 disabled:bg-transparent disabled:text-white disabled:cursor-not-allowed disabled:shadow-none disabled:translate-y-1 disabled:opacity-60 min-w-[80px]
            " disabled={isSubmitting}>
                {
                    isSubmitting ?
                        <VscLoading className="animate-spin" />
                        :
                        "Submit"
                }
            </button>

        </form>
    )
}