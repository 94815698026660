import { createSlice } from "@reduxjs/toolkit";
import { getNightmodeValue } from "../../helpers/methods";
import { LS_NIGHTMODE } from "../../constants";


export const initialState = {
  loading: false,
  message: null,
  errors: null,
  darkMode: getNightmodeValue(),
};

// A slice
const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    loadingUi: (state) => {
      state.loading = true;
    },
    stopLoadingUi: (state) => {
      state.loading = false;
    },
    clearMessage: (state) => {
      state.message = null;
    },
    setMessage: (state, action) => {
      try {
        state.message = {
          error: false,
          message: action?.payload,
        };
      } catch (err) {
        state.message = {
          error: true,
        };
      }
    },
    setError: (state, action) => {
      try {
        state.message = {
          error: true,
          message: action?.payload[0],
        };
      } catch (err) {
        state.message = {
          error: true,
        };
      }
    },
    setDarkMode: (state, action) => {
      state.darkMode = action.payload;
    },
  },
});

const {
  loadingUi,
  stopLoadingUi,
  clearMessage,
  setMessage,
  setError,
  setDarkMode,
} = uiSlice.actions;

export const uiSelector = (state) => state.ui;

const uiReducer = uiSlice.reducer;

export default uiReducer;

// Actions
export const startUiLoading = () => async (dispatch) => {
  dispatch(loadingUi());
};

export const stopUiLoading = () => async (dispatch) => {
  dispatch(stopLoadingUi());
};

export const clearUiMessage = () => async (dispatch) => {
  dispatch(clearMessage());
};

export const setUiError = (errorMessage) => async (dispatch) => {
  dispatch(setError(errorMessage));
};

export const setUiMessage = (message) => async (dispatch) => {
  dispatch(setMessage(message));
};

export const setDarkModeHandler = (darkMode) => async (dispatch) => {
  dispatch(setDarkMode(darkMode));
  localStorage.setItem(LS_NIGHTMODE, darkMode)
};

