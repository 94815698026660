import { FaGithubSquare, FaTwitterSquare, FaLinkedin } from 'react-icons/fa';

export default function SocialMedias({ isLight }) {
    return (
        <div className='flex'>
            <a href='https://www.linkedin.com/in/sushil-bajracharya/' className='ml-1' target='_blank' rel="noreferrer" >
                <FaLinkedin className={`text-4xl ${isLight ? 'text-red-100 hover:text-white' : 'hover:text-blue-600'} `} />
            </a>
            <a href='https://github.com/SushilBajracharya01' className='ml-1' target='_blank' rel="noreferrer" >
                <FaGithubSquare className={`text-4xl ${isLight ? 'text-red-100 hover:text-white' : 'hover:text-slate-500'} `} />
            </a>
            <a href='https://twitter.com/SushilBajra' className='ml-1' target='_blank' rel="noreferrer" >
                <FaTwitterSquare className={`text-4xl ${isLight ? 'text-red-100 hover:text-white' : 'hover:text-blue-400'}  `} />
            </a>
        </div>
    )
}