import { motion } from "framer-motion";

//
import FloatingProgrammer from "./FloatingProgrammer";

/**
 * 
 */
export default function Hero() {
  return (
    <div className="container mx-auto flex flex-col-reverse md:flex-row items-center justify-center md:justify-between flex-wrap mb-10 h-[60vh] md:h-[75vh]">
      <div className="w-full md:w-2/5">
        <motion.div
          className="text-2xl font-bold text-center md:text-left"
          animate={{ x: 0, opacity: 1 }}
          initial={{ x: 150, opacity: 0 }}
          transition={{ delay: 0.5, duration: 1.2 }}
        >
          Hi! I'm
        </motion.div>
        <motion.h1
          className="font-bold font-poppins text-6xl text-center md:text-left mt-4 mb-6 text-red-400 dark:text-white"
          animate={{ x: 0, opacity: 1 }}
          initial={{ x: 300, opacity: 0 }}
          transition={{ delay: 0.8, duration: 1.2 }}
        >
          Sushil Bajracharya
        </motion.h1>
        <motion.p
          className="mt-2 text-xl text-center md:text-left"
          animate={{ x: 0, opacity: 1 }}
          initial={{ x: 500, opacity: 0 }}
          transition={{ delay: 1, duration: 1.6 }}
        >
          An Aspiring frontend and UI/UX Developer{" "}
        </motion.p>
        <motion.p
          className="mt-8 text-xl text-center md:text-left"
          animate={{ x: 0, opacity: 1 }}
          initial={{ x: 600, opacity: 0 }}
          transition={{ delay: 1.6, duration: 2 }}
        >
          <a href="#contact" className="mt-6 bg-red-400 dark:bg-red-500 text-white px-6 py-3 rounded-md hover:shadow-lg hover:-translate-y-1  transition-transform active:translate-y-0 active:shadow-none ">
            Contact me
          </a>
        </motion.p>
      </div>
      <motion.div
        className="w-3/6 justify-self-center md:w-2/5 hidden md:block"
        initial={{ x: -200, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ delay: 3, duration: 1 }}
      >
        <FloatingProgrammer />
      </motion.div>
    </div>
  );
}
