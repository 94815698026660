import { AiFillHeart } from 'react-icons/ai';
import SocialMedias from '../common/SocialMedias/SocialMedias';

export default function Footer() {
    const year = new Date().getFullYear();
    return (
        <div className='container mx-auto py-8 px-8 flex flex-col items-center md:flex-row md:justify-between gap-y-2'>
            <div className='flex flex-col sm:flex-row items-center text-slate-700 dark:text-slate-200'>
                <div className='mb-2 sm:mb-0'>
                    &#169;<span className='mr-3'>{year}</span>
                </div>

                Made with <AiFillHeart className='text-red-500 mx-1' /> by Sushil Bajracharya
            </div>

            <SocialMedias />
        </div>
    )
}