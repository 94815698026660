export const navData = [
  {
    title: "Home",
    href: "/",
  }, {
    title: "About",
    href: "/p/about-me",
  }, {
    title: "Projects",
    href: "/p/projects",
  }, {
    title: "Contact",
    href: "/p/contact",
  },
];
