//
import Hero from "../../sections/Hero";
import About from "../../sections/About";
import Projects from "../../sections/Projects";
import ContactMe from "../../sections/ContactMe";

import transition from '../../transition';
import Blogs from "../../sections/Blogs";

/**
 * 
 */
function LandingPage() {
    return (
        <div>
            <Hero />
            <About />
            <Projects />
            <Blogs />
            <ContactMe />
        </div>
    );
}

export default transition(LandingPage);
