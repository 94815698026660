import { useState } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

//
import HeaderLink from "./NavLink";
import NightModeToggle from "../NightModeToggle";

//
import { navData } from "./_nav-data";

import SBLogo from '../../assets/images/SB.png';
import SBLogoDark from '../../assets/images/dark_SB.png';
import { useSelector } from "react-redux";

/**
 * 
 */
export default function DynamicHeader() {
  const darkMode = useSelector(state => state.ui.darkMode);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const logo = darkMode ? SBLogoDark : SBLogo;
  return (
    <motion.div
      animate={{ y: 0, opacity: 1 }}
      initial={{ y: -100, opacity: 0 }}
      transition={{ duration: 0.8 }}
      className="flex px-6 py-5 flex-wrap items-center justify-between sticky top-0 z-50 bg-opacity-60 backdrop-filter backdrop-blur text-slate-800 bg-slate-100 dark:text-slate-200 dark:bg-slate-800  transition-colors duration-1000"
    >
      <div className="container mx-auto flex justify-between">
        <Link to="/">
          <img src={logo}
            className="fill-current mr-2 w-[42px] h-[30px] object-contain inline"
            alt="sushil Bajracharya"
          />
        </Link>

        <div
          className={`block flex-grow md:flex md:items-center md:w-auto transition-all	${isMenuOpen ? "flex justify-center" : "hidden"
            }`}
        >
          <div className="flex flex-col md:flex-row justify-center md:flex-grow">
            {navData.map((navItem) => (
              <HeaderLink
                key={navItem.title}
                title={navItem.title}
                href={navItem.href}
              />
            ))}
          </div>
          <div className="block mt-4 md:inline-block md:mt-0 ">
            <NightModeToggle />
          </div>
        </div>

        <div className="block md:hidden">
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="flex items-center px-3 py-2 border rounded border-red-400 "
          >
            {isMenuOpen ? (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="fill-current h-3 w-3 text-red-400 rotate-45	 "
              >
                <path
                  d="M12 4C11.4477 4 11 4.44772 11 5V11H5C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13H11V19C11 19.5523 11.4477 20 12 20C12.5523 20 13 19.5523 13 19V13H19C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11H13V5C13 4.44772 12.5523 4 12 4Z"
                  fill="currentColor"
                />
              </svg>
            ) : (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="fill-current h-3 w-3 text-red-400 "
              >
                <path
                  d="M2 6C2 5.44772 2.44772 5 3 5H21C21.5523 5 22 5.44772 22 6C22 6.55228 21.5523 7 21 7H3C2.44772 7 2 6.55228 2 6Z"
                  fill="currentColor"
                />
                <path
                  d="M2 12.0322C2 11.4799 2.44772 11.0322 3 11.0322H21C21.5523 11.0322 22 11.4799 22 12.0322C22 12.5845 21.5523 13.0322 21 13.0322H3C2.44772 13.0322 2 12.5845 2 12.0322Z"
                  fill="currentColor"
                />
                <path
                  d="M3 17.0645C2.44772 17.0645 2 17.5122 2 18.0645C2 18.6167 2.44772 19.0645 3 19.0645H21C21.5523 19.0645 22 18.6167 22 18.0645C22 17.5122 21.5523 17.0645 21 17.0645H3Z"
                  fill="currentColor"
                />
              </svg>
            )}
          </button>
        </div>
      </div>
    </motion.div>
  );
}