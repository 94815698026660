import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDarkModeHandler } from "../_redux/slices/ui";
import { BsSun, BsMoon } from "react-icons/bs";

const NightModeToggle = () => {
  const dispatch = useDispatch();
  const darkMode = useSelector((state) => state.ui.darkMode);

  const handleDarkModeChange = () => {
    dispatch(setDarkModeHandler(!darkMode));
  };
  return (
    <div className="cursor-pointer " onClick={handleDarkModeChange}>
      {darkMode ? (
        <BsMoon
          className="hover:scale-150 transition-transform"
        />
      ) : (
        <BsSun className="hover:scale-150 transition-transform" />
      )}
    </div>
  );
};

export default NightModeToggle;
