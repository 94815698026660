import TechNotesImage from '../../assets/projects/technotes.png';
import CircleLandingImage from '../../assets/projects/CircleLanding.png';
import CircleFEImage from '../../assets/projects/circleFE.png';
import SpliceLandingImage from '../../assets/projects/spliceLanding.png';
import LionheartsImage from '../../assets/projects/lionhearts.png';
import RockPaperScissorImage from '../../assets/projects/rockpaperscissor.jpeg';

export const PROJECTS = [
    {
        id: 5,
        title: 'Lionhearts Apperal Store',
        description: 'Responsive and beautiful e-commerce website page crafted with Nextjs, Typescript and Tailwind CSS. It is integrated with mongodb database and google oauth.',
        linkToRepo: "https://github.com/SushilBajracharya01/ecommerce-base",
        linkToWebsite: "https://ecommerce-base-six.vercel.app",
        image: LionheartsImage,
        hashtags: ["Nextjs", "Responsive", "Mongodb", "Tailwind", "Typescript", "User Experience"]
    },
    {
        id: 2,
        title: 'Circle Landing Page',
        description: 'Responsive and beautiful landing page crafted with Typescript and Tailwind CSS. It is integrated with mailing feature using Brevo.',
        linkToRepo: "https://github.com/SushilBajracharya01/circle-landing-page",
        linkToWebsite: "https://circle-landing-page.onrender.com/",
        image: CircleLandingImage,
        hashtags: ["Responsive", "Reactjs", "Typescript", "User Experience"]
    },
    {
        id: 6,
        title: 'Scissor Paper Rock',
        description: 'React Native project. It is an android app where you play against the computer in a game of rock, paper, scissor. Made with assets created by AI and refined by me.',
        linkToRepo: "https://github.com/SushilBajracharya01/scissor-paper-rock",
        linkToWebsite: "https://github.com/SushilBajracharya01/scissor-paper-rock/releases",
        image: RockPaperScissorImage,
        hashtags: ["ReactNative",  "Typescript", "UI & UX", "ReactNavigation"]
    },
    {
        id: 1,
        title: 'TechNotes',
        description: 'The Tech Notes is a dynamic and user-friendly web-based platform designed to streamline the process of note-taking and tracking.',
        linkToRepo: "https://github.com/SushilBajracharya01/technotes",
        linkToWebsite: "https://technotes-sushil.onrender.com/",
        image: TechNotesImage,
        hashtags: ["MERN", "JWT Authentication", "Tailwind", "NoteTaking"]
    },
    {
        id: 3,
        title: 'Circle',
        description: 'A Social networking website with features to post contents, comment on it and chat with friends.',
        linkToFrontEndRepo: "https://github.com/SushilBajracharya01/circle-fe",
        linkToBackEndRepo: "https://github.com/SushilBajracharya01/circle-be",
        // linkToWebsite: "https://technotes-sushil.onrender.com/",
        image: CircleFEImage,
        hashtags: ["MERN", "Social media", "JWT-Auth", "Tailwind", "Cloudinary"]
    },
    {
        id: 4,
        title: 'Splice',
        description: 'Striking and responsive landing page for the Splice website. Created utilizing the versatility of Tailwind CSS with the initial design by talented Nate Holland in Figma.',
        linkToRepo: "https://github.com/SushilBajracharya01/Splice",
        linkToWebsite: "https://splice-landing-page.onrender.com/",
        image: SpliceLandingImage,
        hashtags: ["Responsive", "Figma", "Tailwind", "Beautiful UI"]
    },
]