import React from "react";
import styles from "./floatingProgrammercss.module.css";

function FloatingProgrammer() {
  return (
    <svg
      className="animated"
      id="freepik_stories-developer-activity"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 500"
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="freepik--background-simple--inject-10"
        className="animable"
        style={{ transformOrigin: "250px 220.489px" }}
      >
        <path
          d="M456.55,209.11c-48.74-83.48-180.95-53.75-207.95-94.35C234.77,94,219.3,69.87,196.86,57.34c-21.39-12-46.19-16.21-67.81-14.63C51.84,48.36,19.11,117.44,33.94,193.3,53.74,294.49,143.44,397.86,250,398.58,305.74,399,359,379,400.7,350.87l.93-.63C443.33,321.85,493.77,272.84,456.55,209.11Z"
          style={{
            fill: "rgb(255, 114, 94)",
            transformOrigin: "250px 220.489px",
          }}
          id="el82ypp4tbibu"
          className="animable"
        />
        <g id="el4j9wm9dua52">
          <path
            d="M456.55,209.11c-48.74-83.48-180.95-53.75-207.95-94.35C234.77,94,219.3,69.87,196.86,57.34c-21.39-12-46.19-16.21-67.81-14.63C51.84,48.36,19.11,117.44,33.94,193.3,53.74,294.49,143.44,397.86,250,398.58,305.74,399,359,379,400.7,350.87l.93-.63C443.33,321.85,493.77,272.84,456.55,209.11Z"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: "0.7",
              transformOrigin: "250px 220.489px",
            }}
            className="animable"
          />
        </g>
      </g>
      <g
        id="freepik--Lines--inject-10"
        className="animable"
        style={{ transformOrigin: "168.685px 221.905px" }}
      >
        <path
          d="M78.19,215.18H72.77a21.93,21.93,0,0,0-21.93,21.93V357.45a21.93,21.93,0,0,0,21.93,21.93h74.36a21.93,21.93,0,0,0,21.93-21.93h0"
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.10311px",
            strokeDasharray: "4.41242",
            transformOrigin: "109.95px 297.28px",
          }}
          id="el3xm0llclpwv"
          className="animable"
        />
        <path
          d="M163,101.63V77.86A13.43,13.43,0,0,1,176.4,64.43h96.7a13.43,13.43,0,0,1,13.43,13.43h0"
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.10311px",
            strokeDasharray: "4.41242",
            transformOrigin: "224.765px 83.03px",
          }}
          id="eliyvbx2tw3f9"
          className="animable"
        />
      </g>
      <g
        id="freepik--Shadow--inject-10"
        className="animable"
        style={{ transformOrigin: "250px 445.4px" }}
      >
        <ellipse
          cx={250}
          cy="445.4"
          rx="191.52"
          ry="26.76"
          style={{
            fill: "rgb(255, 114, 94)",
            transformOrigin: "250px 445.4px",
          }}
          id="elwgec6e9zh5"
          className="animable"
        />
        <g id="elk7ocga41ncl">
          <ellipse
            cx={250}
            cy="445.4"
            rx="191.52"
            ry="26.76"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: "0.5",
              transformOrigin: "250px 445.4px",
            }}
            className="animable"
          />
        </g>
      </g>
      <g
        id="freepik--Window--inject-10"
        className="animable"
        style={{ transformOrigin: "250px 223.55px" }}
      >
        <path
          d="M430.54,133.52V313.58a22.2,22.2,0,0,1-22.19,22.2H91.65a22.2,22.2,0,0,1-22.19-22.2V133.52c0-.53,0-1,.07-1.56a22.18,22.18,0,0,1,22.12-20.64h316.7A22.18,22.18,0,0,1,430.47,132C430.52,132.48,430.54,133,430.54,133.52Z"
          style={{
            fill: "rgb(255, 255, 255)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.10311px",
            transformOrigin: "250px 223.55px",
          }}
          id="elws2dfpboj"
          className="animable"
        />
        <path
          d="M80.26,140.28H420.87a0,0,0,0,1,0,0V306.61a20.8,20.8,0,0,1-20.8,20.8h-299a20.8,20.8,0,0,1-20.8-20.8V140.28a0,0,0,0,1,0,0Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "250.565px 233.845px",
          }}
          id="elgwlg84jhf0g"
          className="animable"
        />
        <g id="elxi6iep99q0r">
          <path
            d="M420.88,140.28V306.61a20.69,20.69,0,0,1-20.57,20.8H100.83a20.69,20.69,0,0,1-20.57-20.8V140.28Z"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: "0.2",
              transformOrigin: "250.57px 233.845px",
            }}
            className="animable"
          />
        </g>
        <path
          d="M430.47,132H69.53a22.18,22.18,0,0,1,22.12-20.64h316.7A22.18,22.18,0,0,1,430.47,132Z"
          style={{
            fill: "rgb(255, 114, 94)",
            stroke: "rgb(38, 50, 56)",
            strokeMiterlimit: 10,
            strokeWidth: "1.10311px",
            transformOrigin: "250px 121.68px",
          }}
          id="els44xj6x5os"
          className="animable"
        />
        <path
          d="M113.88,121.64a3.93,3.93,0,1,1-3.93-3.93A3.93,3.93,0,0,1,113.88,121.64Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "109.95px 121.64px",
          }}
          id="elbkp1zr3fros"
          className="animable"
        />
        <path
          d="M130.5,121.64a3.93,3.93,0,1,1-3.93-3.93A3.93,3.93,0,0,1,130.5,121.64Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "126.57px 121.64px",
          }}
          id="eldnvhktdvjyd"
          className="animable"
        />
        <circle
          cx="143.19"
          cy="121.64"
          r="3.93"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "143.19px 121.64px",
          }}
          id="elcji0ur9ggtb"
          className="animable"
        />
        <path
          d="M420.87,140.28V306.61a20.8,20.8,0,0,1-20.8,20.8h-299a20.8,20.8,0,0,1-20.4-16.76v-.06a21,21,0,0,1-.38-4V140.28Z"
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeMiterlimit: 10,
            strokeWidth: "1.10311px",
            transformOrigin: "250.58px 233.845px",
          }}
          id="el79w50wqpxrr"
          className="animable"
        />
        <path
          d="M420.55,166a20.94,20.94,0,0,0-4.62-1.91l-1.87-8.35h-7.94l-1.85,8.35a20.25,20.25,0,0,0-4.64,1.91l-7.17-4.59L386.85,167l4.51,7.17a20.35,20.35,0,0,0-1.91,4.64l-8.35,1.85v7.94l8.35,1.87a20,20,0,0,0,1.91,4.66l-4.59,7.22,5.62,5.61,7.21-4.59a20.39,20.39,0,0,0,4.63,1.92l1.86,8.34H414l1.86-8.34a20.78,20.78,0,0,0,4.62-1.92l.37.24V165.8Zm-10.44,29a10.26,10.26,0,1,1,10.26-10.25A10.25,10.25,0,0,1,410.11,195Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "400.975px 184.685px",
          }}
          id="ela12aeut74po"
          className="animable"
        />
        <path
          d="M277.45,174.05V157.31l-17.6-3.91a45.51,45.51,0,0,0-4-9.77l2.13-3.35h-83.1l2.05,3.26a43,43,0,0,0-4,9.76l-17.61,3.92V174l17.61,3.93a43.65,43.65,0,0,0,4,9.84l-9.67,15.21,11.85,11.84,15.2-9.68a44.38,44.38,0,0,0,9.77,4.05L208,226.74H224.7l3.94-17.59a44.64,44.64,0,0,0,9.74-4.05l15.21,9.68,11.84-11.84-9.61-15.21a45.87,45.87,0,0,0,4-9.75Zm-61,13.26a21.63,21.63,0,1,1,21.63-21.63A21.62,21.62,0,0,1,216.45,187.31Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "216.385px 183.51px",
          }}
          id="elingdo7n04p"
          className="animable"
        />
        <path
          d="M166.38,289l.71-13.29-13.77-3.88a37.21,37.21,0,0,0-2.82-7.9l8.32-11.68-8.92-9.89-12.46,7a36.51,36.51,0,0,0-7.59-3.63l-2.38-14.12L114.19,231l-3.84,13.81a35,35,0,0,0-7.9,2.8l-11.67-8.31-9.91,8.91,7.06,12.47a35,35,0,0,0-3.64,7.58l-4,.66v18l2.92.82A34.6,34.6,0,0,0,86,295.65l-5.74,8.05.38,6.89v.06l5.93,6.57,12.48-7.05a34.66,34.66,0,0,0,7.61,3.67L109,327.4h0l.11.57,13.28.7.35-1.27h0l3.49-12.53a35.53,35.53,0,0,0,7.94-2.8l11.65,8.3,9.9-8.9-7-12.48a34.53,34.53,0,0,0,3.59-7.57Zm-35.27-20.75a17.2,17.2,0,1,0-1.28,24.28A17.2,17.2,0,0,0,131.11,268.29Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "123.675px 279.835px",
          }}
          id="elnbgkjje50o"
          className="animable"
        />
        <path
          d="M272.6,269.19a27.25,27.25,0,0,0,.81-6.29l9.55-5-2.64-9.69-10.78.5a27.27,27.27,0,0,0-3.87-5l3.19-10.31-8.7-5-7.28,7.94a28.8,28.8,0,0,0-6.27-.8l-5-9.55-9.68,2.63.51,10.79a27.24,27.24,0,0,0-5,3.87l-10.32-3.19-5,8.69,8,7.27a26,26,0,0,0-.79,6.27l-9.57,5,2.64,9.68,10.8-.51a25.39,25.39,0,0,0,3.85,5l-3.21,10.26,8.71,5,7.24-7.88a26.12,26.12,0,0,0,6.29.79l5,9.56,9.68-2.63-.43-9.22-.07-1.58a26.41,26.41,0,0,0,5.06-3.87l10.31,3.2,4.26-7.44.72-1.26Zm-22.87,5.88a13,13,0,1,1,9.1-15.9A13,13,0,0,1,249.73,275.07Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "246.36px 262.605px",
          }}
          id="el6qu7djchrlw"
          className="animable"
        />
        <path
          d="M383.32,286.23l2.2-13.3-13.46-5.41a35.27,35.27,0,0,0-1.93-8.3l9.69-10.79-7.84-11-13.36,5.67a35.38,35.38,0,0,0-7.22-4.48l-.78-14.49-13.3-2.2-5.45,13.4a37.15,37.15,0,0,0-8.27,1.95l-10.82-9.69-10.95,7.84,5.68,13.35a36.24,36.24,0,0,0-4.05,6.29c-.15.31-.31.62-.45.93l-14.48.8-2.21,13.28,2.18.88,11.28,4.55a35.18,35.18,0,0,0,1.93,8.26L292,294.63l7.85,11,13.35-5.7a35.06,35.06,0,0,0,7.21,4.52l.75,14.42,13.29,2.2,5.44-13.3a35.13,35.13,0,0,0,8.28-1.94l10.8,9.69,11-7.85-5.68-13.36a34.78,34.78,0,0,0,4.55-7.25Zm-37.3-.6a17.41,17.41,0,1,1,4-24.29A17.41,17.41,0,0,1,346,285.63Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "335.92px 271.5px",
          }}
          id="el3y4i9kh0j12"
          className="animable"
        />
      </g>
      <g
        id="freepik--Graphics--inject-10"
        className="animable"
        style={{ transformOrigin: "236.115px 228.18px" }}
      >
        <rect
          x="85.39"
          y="187.77"
          width="130.8"
          height="171.01"
          rx="13.11"
          style={{
            fill: "rgb(255, 114, 94)",
            stroke: "rgb(38, 50, 56)",
            strokeMiterlimit: 10,
            strokeWidth: "1.10311px",
            transformOrigin: "150.79px 273.275px",
          }}
          id="elzs8vtsfzm8d"
          className="animable"
        />
        <g id="elcbs1mjvii86">
          <rect
            x="85.39"
            y="187.77"
            width="130.8"
            height="171.01"
            rx="13.11"
            style={{ opacity: "0.2", transformOrigin: "150.79px 273.275px" }}
            className="animable"
          />
        </g>
        <path
          d="M228.36,213.8V358.59a13.11,13.11,0,0,1-13.11,13.12H110.67a13.11,13.11,0,0,1-13.1-13.12V213.8a13.11,13.11,0,0,1,13.1-13.11H215.25A13.11,13.11,0,0,1,228.36,213.8Z"
          style={{
            fill: "rgb(255, 255, 255)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.10311px",
            transformOrigin: "162.965px 286.2px",
          }}
          id="eln0wa8a6s7r"
          className="animable"
        />
        <path
          d="M228.36,213.8v7.75H97.57V213.8a13.11,13.11,0,0,1,13.1-13.11H215.25A13.11,13.11,0,0,1,228.36,213.8Z"
          style={{
            fill: "rgb(255, 114, 94)",
            stroke: "rgb(38, 50, 56)",
            strokeMiterlimit: 10,
            strokeWidth: "1.10311px",
            transformOrigin: "162.965px 211.12px",
          }}
          id="elutwp0rggqtj"
          className="animable"
        />
        <rect
          x="109.87"
          y="230.53"
          width="32.18"
          height="5.4"
          style={{
            fill: "rgb(255, 114, 94)",
            transformOrigin: "125.96px 233.23px",
          }}
          id="elnlkltgva45e"
          className="animable"
        />
        <rect
          x="109.87"
          y="278.92"
          width="32.18"
          height="5.4"
          style={{
            fill: "rgb(255, 114, 94)",
            transformOrigin: "125.96px 281.62px",
          }}
          id="elfzm0ebt8xaa"
          className="animable"
        />
        <rect
          x="109.87"
          y="322.8"
          width="32.18"
          height="5.4"
          style={{
            fill: "rgb(255, 114, 94)",
            transformOrigin: "125.96px 325.5px",
          }}
          id="elt190ovtarg"
          className="animable"
        />
        <rect
          x="109.87"
          y="243.9"
          width="91.39"
          height="3.96"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "155.565px 245.88px",
          }}
          id="eldcouex0yk3"
          className="animable"
        />
        <rect
          x="109.87"
          y="251.47"
          width="74.91"
          height="3.86"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "147.325px 253.4px",
          }}
          id="eltehroj2x8ug"
          className="animable"
        />
        <rect
          x="109.87"
          y="258.92"
          width="84.72"
          height="3.86"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "152.23px 260.85px",
          }}
          id="el9lyl9cv7w14"
          className="animable"
        />
        <rect
          x="109.87"
          y="266.36"
          width="84.72"
          height="3.86"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "152.23px 268.29px",
          }}
          id="elzr1jshkb7wf"
          className="animable"
        />
        <rect
          x="109.87"
          y="287.78"
          width="91.39"
          height="3.96"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "155.565px 289.76px",
          }}
          id="elqowcdmjdxyh"
          className="animable"
        />
        <rect
          x="109.87"
          y="295.35"
          width="74.91"
          height="3.86"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "147.325px 297.28px",
          }}
          id="el8nylwgd6khe"
          className="animable"
        />
        <rect
          x="109.87"
          y="302.8"
          width="84.72"
          height="3.86"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "152.23px 304.73px",
          }}
          id="el4cfk6oc8rru"
          className="animable"
        />
        <rect
          x="109.87"
          y="335.96"
          width="91.39"
          height="3.96"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "155.565px 337.94px",
          }}
          id="elgflam4ihvu"
          className="animable"
        />
        <rect
          x="109.87"
          y="343.54"
          width="74.91"
          height="3.86"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "147.325px 345.47px",
          }}
          id="el5sj7o6xaj3y"
          className="animable"
        />
        <rect
          x="109.87"
          y="350.99"
          width="84.72"
          height="3.86"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "152.23px 352.92px",
          }}
          id="elc49hb2tk64"
          className="animable"
        />
        <path
          d="M375.77,100.29v58.52a15.63,15.63,0,0,1-15.64,15.63H225.35a15.63,15.63,0,0,1-15.64-15.63V100.29a15.65,15.65,0,0,1,15.64-15.64H360.13A15.64,15.64,0,0,1,375.77,100.29Z"
          style={{
            fill: "rgb(255, 255, 255)",
            stroke: "rgb(34, 34, 33)",
            strokeMiterlimit: 10,
            strokeWidth: "1.10311px",
            transformOrigin: "292.74px 129.545px",
          }}
          id="el256awa01der"
          className="animable"
        />
        <path
          d="M375.77,100.29v1.34H209.71v-1.34a15.65,15.65,0,0,1,15.64-15.64H360.13A15.64,15.64,0,0,1,375.77,100.29Z"
          style={{
            fill: "rgb(255, 114, 94)",
            stroke: "rgb(38, 50, 56)",
            strokeMiterlimit: 10,
            strokeWidth: "1.10311px",
            transformOrigin: "292.74px 93.14px",
          }}
          id="el3mnbmekmkdw"
          className="animable"
        />
        <path
          d="M234.11,95.94a2.4,2.4,0,1,1,0-3.39A2.41,2.41,0,0,1,234.11,95.94Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "232.409px 94.245px",
          }}
          id="eljksmqqf3lf"
          className="animable"
        />
        <path
          d="M245.23,95.94a2.4,2.4,0,1,1,0-3.39A2.41,2.41,0,0,1,245.23,95.94Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "243.529px 94.245px",
          }}
          id="eloenw0m3thag"
          className="animable"
        />
        <path
          d="M256.35,95.94a2.4,2.4,0,1,1,0-3.39A2.41,2.41,0,0,1,256.35,95.94Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "254.649px 94.245px",
          }}
          id="elw4942w02a8q"
          className="animable"
        />
        <rect
          x="220.3"
          y="112.39"
          width="54.28"
          height="46.92"
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.10311px",
            strokeDasharray: "4.41242",
            transformOrigin: "247.44px 135.85px",
          }}
          id="elvoyc3gt9io"
          className="animable"
        />
        <rect
          x="282.96"
          y="112.39"
          width="62.47"
          height="3.3"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "314.195px 114.04px",
          }}
          id="el2ws2eikx50u"
          className="animable"
        />
        <rect
          x="282.96"
          y="119.22"
          width="47.92"
          height="3.29"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "306.92px 120.865px",
          }}
          id="elem2ttp2vwap"
          className="animable"
        />
        <rect
          x="282.96"
          y="126.02"
          width="71.35"
          height="3.33"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "318.635px 127.685px",
          }}
          id="elt6arlkawfo"
          className="animable"
        />
        <rect
          x="282.96"
          y="132.65"
          width="60.76"
          height="3.33"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "313.34px 134.315px",
          }}
          id="elvpss9ac1ue"
          className="animable"
        />
        <rect
          x="343.23"
          y="245.53"
          width="43.61"
          height="30.99"
          rx="6.53"
          style={{
            fill: "rgb(255, 255, 255)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.10311px",
            transformOrigin: "365.035px 261.025px",
          }}
          id="elyv2dc2523zo"
          className="animable"
        />
        <polyline
          points="357.3 254.85 348.91 260.11 356.7 267.01"
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.10311px",
            transformOrigin: "353.105px 260.93px",
          }}
          id="elg50zzei1qwl"
          className="animable"
        />
        <polyline
          points="372.58 267.51 380.12 261.09 371.42 255.39"
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.10311px",
            transformOrigin: "375.77px 261.45px",
          }}
          id="el5ht7o8qbdyk"
          className="animable"
        />
        <line
          x1="361.38"
          y1="271.47"
          x2="367.57"
          y2="252.05"
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.10311px",
            transformOrigin: "364.475px 261.76px",
          }}
          id="elq2qsz30k6z"
          className="animable"
        />
      </g>
      <g
        className={styles.floatingProgrammer}
        style={{ transformOrigin: "309.678px 336.317px" }}
      >
        <path
          d="M296.86,440.47s-29,8.31-33.05,9.75c-3.68,1.3-13.66,4.8-21.15,3.71a15.89,15.89,0,0,1-2.14-.46c-7.59-2.26-11-4.37-12-9.14s2.78-8.29,6.42-10.54,23.38-4.6,23.38-4.6l4.77,1.4h0Z"
          style={{
            fill: "rgb(255, 114, 94)",
            transformOrigin: "262.608px 441.664px",
          }}
          id="eli606sw26lo8"
          className="animable"
        />
        <path
          d="M242.66,453.93a15.89,15.89,0,0,1-2.14-.46c-7.59-2.26-11-4.37-12-9.14s2.78-8.29,6.42-10.54,23.38-4.6,23.38-4.6l4.77,1.4c-4.32,2-20.4,3.09-23.91,5.11s-5.2,5.82-3.13,11C237.16,449.4,240.12,452.05,242.66,453.93Z"
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "245.723px 441.56px",
          }}
          id="elej79nir2m1f"
          className="animable"
        />
        <path
          d="M292.5,441.65s-21.78,5.64-30.42,9.25-25.11,5.19-31-2.25-.68-13.86,5.19-15.29,17.74-3.68,24.22-2.77"
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.10311px",
            transformOrigin: "260.496px 442.151px",
          }}
          id="elk7kbs8qxpu"
          className="animable"
        />
        <path
          d="M243.09,453.54s-11.08-6.08-7.4-14.6c2.72-6.28,17.5-4.92,24.82-8.35"
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.10311px",
            transformOrigin: "247.726px 442.065px",
          }}
          id="ele4zubmnfrd8"
          className="animable"
        />
        <path
          d="M385.19,455.28c-.3,3-1.35,5.81-4.35,7.58-2,1.18-4.84,1.88-8.91,1.84-10.17-.13-29.29-7.4-29.29-7.4l-17.9-9.9,9.31-14.73,2.55-4,6.58,1.48s5.45,3.09,10.93,5.55,18.79,3,23.48,6.77S385.7,450.34,385.19,455.28Z"
          style={{
            fill: "rgb(255, 114, 94)",
            transformOrigin: "354.99px 446.686px",
          }}
          id="el0r7rkgedmoyq"
          className="animable"
        />
        <path
          d="M385.19,455.28c-.3,3-1.35,5.81-4.35,7.58a13.39,13.39,0,0,0-.5-8.94c-2-4.49-8.51-8.45-17.07-9.11s-15.11-4.66-18.54-6.45c-2.68-1.39-8.33-4.43-10.68-5.69l2.55-4,6.58,1.48s5.45,3.09,10.93,5.55,18.79,3,23.48,6.77S385.7,450.34,385.19,455.28Z"
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "359.645px 445.765px",
          }}
          id="elyccxc6alfe8"
          className="animable"
        />
        <path
          d="M328.71,450.18a112.86,112.86,0,0,0,41.9,14.31c3.26.39,7.05.45,9.23-2a6.82,6.82,0,0,0,1.5-5.11c-.39-4.6-4.26-8.24-8.5-10.07s-8.91-2.25-13.42-3.26a53.38,53.38,0,0,1-12.9-4.72c-3.24-1.69-6.37-3.74-9.94-4.5s-7.82.16-9.73,3.28"
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.10311px",
            transformOrigin: "354.118px 449.635px",
          }}
          id="el2jp3tb8mv2w"
          className="animable"
        />
        <path
          d="M378.64,463.74a11.92,11.92,0,0,0,6.55-8.46c.65-4.33-1.82-8.69-5.29-11.35s-7.82-3.92-12.11-4.81S359.11,437.63,355,436c-6.27-2.46-12.46-7.3-19-5.57"
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.10311px",
            transformOrigin: "360.647px 446.902px",
          }}
          id="el5ctesnvfxwj"
          className="animable"
        />
        <path
          d="M348.91,295.26l-30.29-11.52-.38-.15s.11-.8.2-2c.19-2.42.29-6.36-.85-7.69-1.69-2-6.47-.32-11.14-2.46s-13.3,1.69-13.3,1.69-6.24-1.15-9,3.35c-1.4,3.63-.92,9-.92,9l-.57.2c-3.61,1.35-23.25,8.74-27.4,11.71-4.59,3.25-9.67,36.62-11.3,46.42s-4.53,18.63-3.06,22,22.57,19.62,34.71,23.81,55.19,3,55.19,3,.7-.19,1.95-.59c7.28-2.34,33.11-12,46.82-34.68C379.59,357.39,366.31,313.86,348.91,295.26Zm-81.84,56.13c-2.23,1.58-1,7.7-1,7.7l-3.79-3.34,9.52-23.41,4.06,18S269.3,349.82,267.07,351.39Zm76.16,14.87c-1.05.54,3-10.71,1.08-13.26s-7.71-2.31-7.71-2.31l.49-3.07,3.43-17.6,1.14-1.35s11.78,20.19,13.89,27.31C355.55,356,342.71,366.26,343.23,366.26Z"
          style={{
            fill: "rgb(255, 114, 94)",
            transformOrigin: "310.033px 331.783px",
          }}
          id="el4uprsz0noy9"
          className="animable"
        />
        <path
          d="M291.72,222.25l-15.64,16.6s-2.15,1.91-1.3,6.72,2.85,9,4.36,9.28a21.37,21.37,0,0,0,3.62.32s3.35,7.26,5.73,10.11c0,0,.82,11.21.38,14.32,0,0,5.62,4.91,10.91,5.53s13.46-7.48,13.46-7.48l2-9.92s4.8-7.28,5.12-9.22,2.52-1.44,3.6-2.4,3.57-5.23,4-9.27.44-8.68-4.25-7.32c-4.07-1.09-1.52-9.69-1.52-9.69l-7.15-6.3Z"
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "301.36px 253.707px",
          }}
          id="elbbwtis509un"
          className="animable"
        />
        <g id="elrgtwc8slywe">
          <path
            d="M318.62,283.74a17.67,17.67,0,0,1-.81,1.81c-2.58,4.86-13.82,14.13-13.82,14.13l-3.4-10.45-1.86,10.45c-3.75-1.64-15.19-11.55-15.19-11.55s-.39-.93-.86-2.38l.57-.2s-.48-5.41.92-9c2.74-4.5,9-3.35,9-3.35s8.63-3.83,13.3-1.69,9.45.48,11.14,2.46.65,9.66.65,9.66Z"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: "0.5",
              transformOrigin: "300.65px 285.27px",
            }}
            className="animable"
          />
        </g>
        <g id="elcv7nuj9uwdk">
          <path
            d="M348.91,295.26l-30.29-11.52-.38-.15s.11-.8.2-2l-.63,3.93-4.48,6.08a124,124,0,0,1,14,6.16A12.28,12.28,0,0,1,333.73,310l-5.58,47.75-2.29,27.11,6.91,7.17c7.28-2.34,33.11-12,46.82-34.68C379.59,357.39,366.31,313.86,348.91,295.26Zm-5.68,71c-1.05.54,3-10.71,1.08-13.26s-7.71-2.31-7.71-2.31l.49-3.07,3.43-17.6,1.14-1.35s11.78,20.19,13.89,27.31C355.55,356,342.71,366.26,343.23,366.26Z"
            style={{ opacity: "0.2", transformOrigin: "346.46px 336.81px" }}
            className="animable"
          />
        </g>
        <g id="el1hy161myhjo">
          <path
            d="M277.87,353l-4.71-15.74s-8.45-28.81-14.24-35.91l1.52,20.26s-5,24.2-9.57,31.39-5.59,9,0,9,19.2,10.84,19.2,10.84l-1.05-9ZM266,359.09l-3.79-3.34,9.52-23.41,4.06,18s-6.52-.49-8.75,1.08S266,359.09,266,359.09Z"
            style={{ opacity: "0.2", transformOrigin: "262.46px 337.095px" }}
            className="animable"
          />
        </g>
        <path
          d="M288.49,265.28s.86,8.49,0,12.37"
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.10311px",
            transformOrigin: "288.681px 271.465px",
          }}
          id="elhxt6fopsa9d"
          className="animable"
        />
        <path
          d="M314.11,276.51a36.53,36.53,0,0,1,.94-8.11"
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.10311px",
            transformOrigin: "314.58px 272.455px",
          }}
          id="el9fxlxkxzhn"
          className="animable"
        />
        <path
          d="M316.38,228.58s5.14,6.45,4.87,11.33-.65,10.66-.52,16.32-9.09,17.52-13.83,18.57-8.82,1.32-11.85-1.71-6.06-7.24-10-12.64-3-17.25-3-17.25"
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.10311px",
            transformOrigin: "301.606px 252.052px",
          }}
          id="el6fagaoypim6"
          className="animable"
        />
        <path
          d="M304,247.71a26.82,26.82,0,0,0-.4,5.37,6.05,6.05,0,0,0,1.32,4.2c.57.58,1.39,1.05,1.5,1.85a2,2,0,0,1-1.06,1.75,15.55,15.55,0,0,0-1.85,1.1"
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.10311px",
            transformOrigin: "304.965px 254.845px",
          }}
          id="elvtqhnxeygq"
          className="animable"
        />
        <path
          d="M295.87,262.6s.59,3.83,5.72,4.63"
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.10311px",
            transformOrigin: "298.73px 264.915px",
          }}
          id="elb3n9vimbvj4"
          className="animable"
        />
        <path
          d="M296.4,250.63c0,1.11-.48,2-1.07,2s-1.07-.89-1.07-2,.48-2,1.07-2S296.4,249.53,296.4,250.63Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "295.33px 250.63px",
          }}
          id="elwj2a1aerjab"
          className="animable"
        />
        <path
          d="M311.71,251.11c0,1.11-.48,2-1.07,2s-1.07-.9-1.07-2,.48-2,1.07-2S311.71,250,311.71,251.11Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "310.64px 251.11px",
          }}
          id="elbgovmwfmgit"
          className="animable"
        />
        <path
          d="M308.62,246.26s5-5,9.19.58"
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "2.20621px",
            transformOrigin: "313.215px 245.52px",
          }}
          id="el7jsddsi2e6g"
          className="animable"
        />
        <path
          d="M298.09,246.36s-3.16-3.54-8.14,0"
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "2.20621px",
            transformOrigin: "294.02px 245.573px",
          }}
          id="el0a3v951vxlsh"
          className="animable"
        />
        <path
          d="M288.87,270s3.05,8.4,9.86,10.79,15.85-10,15.85-10l-.47-1.61s-5.93,7.7-12.52,6.39-13.1-10.25-13.1-10.25Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "301.535px 273.208px",
          }}
          id="el8wr5gpkgtz9"
          className="animable"
        />
        <path
          d="M282.76,254.85s-4.06,1.43-5.14-1.73-4.78-11.49-1.54-14.27,5.84,3.67,5.84,3.67"
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.10311px",
            transformOrigin: "278.769px 246.664px",
          }}
          id="elycyauihw3x"
          className="animable"
        />
        <path
          d="M320.72,256.57s3.77.76,4.85-2.4,4.78-11.49,1.54-14.27-5.84,3.67-5.84,3.67"
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.10311px",
            transformOrigin: "324.566px 247.927px",
          }}
          id="ellwx28wvh1xg"
          className="animable"
        />
        <path
          d="M281.92,250.63a5.62,5.62,0,0,0,3.56-2.1,8.74,8.74,0,0,0,1-3.19,37.59,37.59,0,0,0,.56-6.44,8.81,8.81,0,0,1,.23-2.51,6.6,6.6,0,0,1,1.36-2.23c1.41-1.66,3.12-3.06,4.47-4.78a8,8,0,0,0,2-6,27.45,27.45,0,0,0,22.47,5.81c7.15-1.4,14-7.67,13.17-15.55a6.37,6.37,0,0,0-5-5.48c-3.53-.88-6.7,1-9.9,2.21-5.83,2.2-11.54-1.58-17.43-2.21-5-.55-11.23.08-13.85,5.09,0,0-9,1.06-13,8.5s3.57,17.73,3.57,17.73,3-3.68,6.93,3.76Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "300.601px 229.282px",
          }}
          id="el0jtinma5uphh"
          className="animable"
        />
        <path
          d="M317,226.68s-2.34,12.2,3.77,18.11c0,0,1.34-3.67,2.69-4.57a14.13,14.13,0,0,0-1.07-13.54C317.87,219.58,317,226.68,317,226.68Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "320.631px 234.157px",
          }}
          id="el4yorxudcp72"
          className="animable"
        />
        <g id="elnjb5ud2ybmc">
          <path
            d="M328.14,212.8a11.49,11.49,0,0,1-7,7.27c-8.48,3.46-16.71-.37-25.21-1.12-6.93-.62-14.58,5.64-15.07,12.76"
            style={{
              fill: "none",
              stroke: "rgb(255, 255, 255)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: "1.10311px",
              opacity: "0.2",
              transformOrigin: "304.5px 222.255px",
            }}
            className="animable"
          />
        </g>
        <g id="el0m0xp2cx194">
          <path
            d="M275.63,229.83a14.81,14.81,0,0,1,9-13.08"
            style={{
              fill: "none",
              stroke: "rgb(255, 255, 255)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: "1.10311px",
              opacity: "0.2",
              transformOrigin: "280.13px 223.29px",
            }}
            className="animable"
          />
        </g>
        <path
          d="M286.53,276.51a38.71,38.71,0,0,0,13.21,9s10.15-2.95,15.31-10"
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.10311px",
            transformOrigin: "300.79px 280.51px",
          }}
          id="elyz1q22rfjqr"
          className="animable"
        />
        <g id="el6pwwdl0m2ar">
          <circle
            cx="293.15"
            cy="250.63"
            r="8.98"
            style={{
              fill: "none",
              stroke: "rgb(38, 50, 56)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: "1.10311px",
              transformOrigin: "293.15px 250.63px",
              transform: "rotate(-24.64deg)",
            }}
            className="animable"
          />
        </g>
        <g id="elydbzo7aum5c">
          <circle
            cx="313.22"
            cy="250.63"
            r="8.98"
            style={{
              fill: "none",
              stroke: "rgb(38, 50, 56)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: "1.10311px",
              transformOrigin: "313.22px 250.63px",
              transform: "rotate(-7deg)",
            }}
            className="animable"
          />
        </g>
        <path
          d="M288.49,273.16a7,7,0,0,0-4.33,3.35c-1.59,2.8-.63,11.61-.63,11.61s8.21,8.12,15.2,11.55l2.06-11.59,3.2,11.59s10.82-7.65,13.82-14.12c0,0,1-7.55,0-10a3.47,3.47,0,0,0-3.23-2.37"
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.10311px",
            transformOrigin: "300.749px 286.415px",
          }}
          id="elaa5n6lkqbal"
          className="animable"
        />
        <path
          d="M275.63,350.7s-3.21-21.5-7.48-30.27"
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.10311px",
            transformOrigin: "271.89px 335.565px",
          }}
          id="el8mxnsw8kvv6"
          className="animable"
        />
        <path
          d="M258.92,301.35a87,87,0,0,1,6.64,11.11"
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.10311px",
            transformOrigin: "262.24px 306.905px",
          }}
          id="els7mab1mism7"
          className="animable"
        />
        <path
          d="M343.23,317.4a62.1,62.1,0,0,1,3.53-10.49"
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.10311px",
            transformOrigin: "344.995px 312.155px",
          }}
          id="elzcmcu5mlatr"
          className="animable"
        />
        <path
          d="M336.6,350.7s2.31-14.74,5.07-26.88"
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.10311px",
            transformOrigin: "339.135px 337.26px",
          }}
          id="eldlywo15iivs"
          className="animable"
        />
        <path
          d="M340.52,330a17,17,0,0,1,8.94-6.12"
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.10311px",
            transformOrigin: "344.99px 326.94px",
          }}
          id="el902topg7z6o"
          className="animable"
        />
        <path
          d="M318.25,283.59s18.63,7.45,30.66,11.68c0,0,14.8,13.91,30.67,62.12,0,0-14.54,24.46-42.2,32.3"
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.10311px",
            transformOrigin: "348.915px 336.64px",
          }}
          id="el3rbdn2rcmig"
          className="animable"
        />
        <path
          d="M342.18,367.13,355.55,356s7.07-5.37,14.42-1.7"
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.10311px",
            transformOrigin: "356.075px 360.074px",
          }}
          id="eloeq1nc1i6po"
          className="animable"
        />
        <path
          d="M341.67,328.67S352.72,345,355.55,356"
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.10311px",
            transformOrigin: "348.61px 342.335px",
          }}
          id="el1ywssph5a2n"
          className="animable"
        />
        <path
          d="M266.15,359.71,262.24,356s-5.8-1-12.38,3.39"
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.10311px",
            transformOrigin: "258.005px 357.806px",
          }}
          id="elybu1bm1xwq"
          className="animable"
        />
        <path
          d="M262.24,356s4.86-16,9.65-24.19"
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.10311px",
            transformOrigin: "267.065px 343.905px",
          }}
          id="eluvozf5fzqy"
          className="animable"
        />
        <path
          d="M283.25,285.55s-26.81,10.06-29.44,13-12.62,59.19-12.89,65.63c-.23,5.76,20.65,18.69,31,24.14"
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.10311px",
            transformOrigin: "262.084px 336.935px",
          }}
          id="eljityvnm200i"
          className="animable"
        />
        <path
          d="M303.3,304.56a1.77,1.77,0,1,1-1.77-1.77A1.76,1.76,0,0,1,303.3,304.56Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "301.53px 304.56px",
          }}
          id="elfycxdrabp58"
          className="animable"
        />
        <path
          d="M334.32,398.78h-61a1.43,1.43,0,0,1-1.43-1.43h0a1.43,1.43,0,0,1,1.43-1.43h61a1.43,1.43,0,0,1,1.43,1.43h0A1.43,1.43,0,0,1,334.32,398.78Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "303.82px 397.35px",
          }}
          id="elo1xzfrm657"
          className="animable"
        />
        <path
          d="M336.46,394l8.17-38.4a4.08,4.08,0,0,0-4-4.93h-71.3a4.08,4.08,0,0,0-4,4.88l7.33,37.12a4.08,4.08,0,0,0,3.91,3.29l55.79,1.28A4.1,4.1,0,0,0,336.46,394Z"
          style={{
            fill: "rgb(255, 255, 255)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.10311px",
            transformOrigin: "304.985px 373.956px",
          }}
          id="elj8g5u4o1uzf"
          className="animable"
        />
        <path
          d="M310.44,372.83a5.69,5.69,0,1,1-5.69-5.7A5.69,5.69,0,0,1,310.44,372.83Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "304.75px 372.82px",
          }}
          id="el5a8f9wxl0b"
          className="animable"
        />
        <line
          x1="321.63"
          y1="354.34"
          x2="330.1"
          y2="354.34"
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.10311px",
            transformOrigin: "325.865px 354.34px",
          }}
          id="elgqcemw392zi"
          className="animable"
        />
        <line
          x1="281.18"
          y1="354.34"
          x2="313.33"
          y2="354.34"
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.10311px",
            transformOrigin: "297.255px 354.34px",
          }}
          id="elzyzw2h23x7l"
          className="animable"
        />
        <path
          d="M336.6,434.77l-6.5,17.06s-16.58-4.18-37.81-8.53c-22.21-4.55-45.4-10.44-57.43-31.55-1.46-2.57-2.44-5.61-1.75-8.49,1.73-7.29,11.22-9.27,17.47-10a113.92,113.92,0,0,1,11.83-.66c2,0,8.65-1.09,10.26.1a47.14,47.14,0,0,0,7.71,4.57c3.55,1.51,55.37-1.35,55.37-1.35a18.59,18.59,0,0,0,1.63-6.23c8.15-4.14,27-7.64,35-6.09,12.53,2.43,19.84,9.74,18.45,17.4S361.76,429.2,336.6,434.77Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "311.941px 417.528px",
          }}
          id="elz9awnzrzrt"
          className="animable"
        />
        <path
          d="M336.6,434.77s-15.77-4.18-28.65-12.88-22.94-18.8-40.89-16.71"
          style={{
            fill: "none",
            stroke: "rgb(255, 255, 255)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.10311px",
            transformOrigin: "301.83px 419.836px",
          }}
          id="ely09zr35dsla"
          className="animable"
        />
        <path
          d="M305,419.84c8.14-2.34,14.11-7.61,20.64-12.66a139.62,139.62,0,0,1,22.09-14.52"
          style={{
            fill: "none",
            stroke: "rgb(255, 255, 255)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.10311px",
            transformOrigin: "326.365px 406.25px",
          }}
          id="elmivnmaene2q"
          className="animable"
        />
        <line
          x1="302.13"
          y1="252.05"
          x2="304.52"
          y2="252.05"
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.10311px",
            transformOrigin: "303.325px 252.05px",
          }}
          id="elzvxle3zbd9m"
          className="animable"
        />
      </g>
      <defs>
        {"{"}" "{"}"}
        <filter id="active" height="200%">
          {"{"}" "{"}"}
          <feMorphology
            in="SourceAlpha"
            result="DILATED"
            operator="dilate"
            radius={2}
          />
          {"{"}" "{"}"}
          <feFlood floodColor="#32DFEC" floodOpacity={1} result="PINK" />
          {"{"}" "{"}"}
          <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
          {"{"}" "{"}"}
          <feMerge>
            {"{"}" "{"}"}
            <feMergeNode in="OUTLINE" />
            {"{"}" "{"}"}
            <feMergeNode in="SourceGraphic" />
            {"{"}" "{"}"}
          </feMerge>
          {"{"}" "{"}"}
        </filter>
        {"{"}" "{"}"}
        <filter id="hover" height="200%">
          {"{"}" "{"}"}
          <feMorphology
            in="SourceAlpha"
            result="DILATED"
            operator="dilate"
            radius={2}
          />
          {"{"}" "{"}"}
          <feFlood floodColor="#ff0000" floodOpacity="0.5" result="PINK" />
          {"{"}" "{"}"}
          <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
          {"{"}" "{"}"}
          <feMerge>
            {"{"}" "{"}"}
            <feMergeNode in="OUTLINE" />
            {"{"}" "{"}"}
            <feMergeNode in="SourceGraphic" />
            {"{"}" "{"}"}
          </feMerge>
          {"{"}" "{"}"}
          <feColorMatrix
            type="matrix"
            values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 "
          />
          {"{"}" "{"}"}
        </filter>
      </defs>
    </svg>
  );
}

export default FloatingProgrammer;
