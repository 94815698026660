import React from "react";
import { NavLink } from "react-router-dom";

function HeaderLink({ title, href, className = "" }) {

  return (
    <NavLink to={href} className={({ isActive }) => `${isActive ? 'text-red-500 font-medium' : ''}`
    }>
      <span
        className={`block text-xl mt-4 md:inline-block md:mt-0 mr-10 hover:text-red-400 dark:hover:text-slate-50 ${className}
        `}
      >
        {title}
      </span>
    </NavLink >
  );
}

export default HeaderLink;
