import Project from "./Project/Project";
import { PROJECTS } from "./_data";

export default function Projects() {
    return (
        <div id="projects" className="container mx-auto min-h-screen py-20">
            <div className="text-center">
                 <h1 className="dark:text-white font-semibold font-poppins text-4xl lg:text-6xl mt-4 md:mt-0 mb-6" data-aos="fade-down">
                    My Recent Projects
                </h1>
                <p data-aos="fade-down" data-aos-delay="200">A small collection of my recent projects</p>
            </div> 
            <div className="my-10 mx-4 grid gap-12 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
                {
                    PROJECTS.map((project, index) => <Project
                        key={project.id}
                        delay={index}
                        title={project.title}
                        description={project.description}
                        linkToRepo={project.linkToRepo}
                        linkToFrontEndRepo={project.linkToFrontEndRepo}
                        linkToBackEndRepo={project.linkToBackEndRepo}
                        linkToWebsite={project.linkToWebsite}
                        image={project.image}
                        hashtags={project.hashtags}
                    />)
                }

            </div>
        </div>
    );
};

