import { useEffect } from "react";
import Projects from "../../sections/Projects";

export default function ProjectsPage() {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    return (
        <Projects />
    )
}