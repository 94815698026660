import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

//
import Layout from "./components/common/Layout/Layout";

//
import LandingPage from "./Pages/LandingPage";

//
import './App.css';
import AboutMePage from "./Pages/AboutMe";
import DynamicLayout from "./components/common/DynamicLayout";
import ProjectsPage from "./Pages/Projects";
import ProjectPage from "./Pages/ProjectPage";
import ContactPage from "./Pages/ContactPage/ContactPage";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { AnimatePresence } from "framer-motion";

//
const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <LandingPage />,
      }
    ],
  },
  {
    path: '/p',
    element: <DynamicLayout />,
    children: [
      {
        path: 'about-me',
        element: <AboutMePage />
      },
      {
        path: 'projects',
        element: <ProjectsPage />
      },
      {
        path: "projects/:id",
        element: <ProjectPage />
      },
      {
        path: "contact",
        element: <ContactPage />
      }
    ]
  }
]);

/**
 * 
 */
export default function App() {
  return (
    <>
      <AnimatePresence mode="wait">
        <RouterProvider router={router} />
      </AnimatePresence>
      <ToastContainer />
    </>
  );
}
