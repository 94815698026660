
import { FaGithub } from 'react-icons/fa';
import { CgWebsite } from 'react-icons/cg';
import { GoServer } from 'react-icons/go';
import { FiMonitor } from 'react-icons/fi';

export default function Project({
    title,
    description,
    image,
    linkToRepo,
    linkToFrontEndRepo,
    linkToBackEndRepo,
    linkToWebsite,
    delay,
    hashtags = [],
}) {
    return (
        <div data-aos="fade-down" data-aos-delay={`${400 + (delay * 200)}`}>
            <div className="max-w-sm mx-auto rounded cursor-default overflow-hidden shadow-lg transition-transform transform hover:scale-105 dark:bg-slate-600  duration-1000 h-full flex flex-col justify-between">
                <div>
                    <img
                        className="w-full max-h-[220px] object-contain"
                        src={image}
                        alt={title}
                    />
                    <div className="px-6 py-4">
                        <div className="font-bold text-xl mb-2">{title}</div>
                        <p className="text-gray-700 dark:text-slate-200 text-base">
                            {description}
                        </p>

                        <div className="pt-3 flex flex-wrap gap-2">
                            {
                                linkToWebsite &&
                                <a className="basis-80 flex-grow border-2 flex justify-center items-center rounded-md py-1.5 mb-1 hover:bg-gray-700 hover:text-slate-100 transition-colors" target="_blank" rel="noreferrer" href={linkToWebsite}>
                                    <CgWebsite fontSize={20} className='mr-1.5' />

                                    View Application
                                </a>
                            }
                            {
                                linkToRepo &&
                                <a className="basis-80 flex-grow border-2 flex justify-center items-center rounded-md py-1.5 mb-1 hover:bg-gray-700 hover:text-slate-100 transition-colors" target="_blank" rel="noreferrer" href={linkToRepo}>
                                    <FaGithub fontSize={20} className='mr-1.5' />

                                    View Repo
                                </a>
                            }
                            {
                                linkToFrontEndRepo &&
                                <a className="basis-80 flex-grow border-2 flex justify-center items-center rounded-md py-1.5 mb-1 hover:bg-gray-700 hover:text-slate-100 transition-colors" target="_blank" rel="noreferrer" href={linkToFrontEndRepo}>
                                    <FiMonitor fontSize={20} className='mr-1.5' />

                                    View FE Repo
                                </a>
                            }
                            {
                                linkToBackEndRepo &&
                                <a className="flex-grow border-2 flex justify-center items-center rounded-md py-1.5 mb-1 hover:bg-gray-700 hover:text-slate-100 transition-colors" target="_blank" rel="noreferrer" href={linkToBackEndRepo}>
                                    <GoServer fontSize={20} className='mr-1.5' />

                                    View BE Repo
                                </a>
                            }
                        </div>
                    </div>
                </div>

                <div className="px-6 pt-4 pb-2">
                    {
                        hashtags.map(hashtag => <span key={hashtag} className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                            #{hashtag}
                        </span>)
                    }
                </div>
            </div>
        </div>
    );
};
