import { LS_NIGHTMODE } from "../constants";

//
export const getNightmodeValue = () => {
    if (localStorage.getItem(LS_NIGHTMODE)) {
        return localStorage.getItem(LS_NIGHTMODE) === "false" ? false : true;
    }
    else {
        return false;
    }
}